/** @format */

import Head from 'next/head'
import { useSelector } from 'react-redux'
import { selectFavicon } from '../redux/configs/configs.selector'
import { getFaviconUrl } from '../utils/getFaviconUrl'

const Meta = ({ title, keyword, desc }) => {
  const favicon = useSelector(selectFavicon)
  return (
    <div>
      <Head>
        <title>{title}</title>
        <link
          rel='icon'
          href={
            favicon
              ? getFaviconUrl(favicon)
              : '/images/cropped-BL-Favicon-e1658219892168-192x192.png'
          }
        />
        <meta name='description' content={desc} />
        <meta name='robots' content='noindex, follow' />
        <meta name='keyword' content={keyword} />
        <meta
          property='og:image'
          content='/images/pedro-marroquin-BaqvjgjuXaQ-unsplash-scaled.jpg'
        />
      </Head>
    </div>
  )
}

Meta.defaultProps = {
  title: 'Billionaires List',
  keyword:
    'bitcoin, blockchain, crypto, crypto collectibles, crypto makretplace, cryptocurrency, digital items, market, nft, nft marketplace, nft next js, NFT react, non-fungible tokens, virtual asset, wallet',
  desc: "The world's first and largest digital marketplace for crypto collectibles and non-fungible tokens (NFTs). Buy, sell, and discover exclusive digital items.",
}

export default Meta
