export const navigationAdditionalLinks = [
  {
    id: 1,
    link: '/celebrities-quotes',
    name: 'Quotes',
  },
  {
    id: 2,
    link: '/numbers-and-statistics',
    name: 'Numbers',
  },
  {
    id: 3,
    link: '/rankings',
    name: 'Ranking',
  },
  {
    id: 4,
    link: '/maps',
    name: 'Maps',
  },
]
