import { createSlice } from '@reduxjs/toolkit'
import { getConfigs } from './configs.actions'

const initialState = {
  categories: [],
  settings: {},
  newestCelebrities: [],
  isLoading: true,
}

export const configsSlice = createSlice({
  name: 'configs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConfigs.fulfilled, (state, { payload }) => {
        state.categories = payload.categories

        state.settings = payload.settings.reduce((acc, curr) => {
          Object.assign(acc, curr)
          return acc
        }, {})

        state.newestCelebrities = payload.newestCelebrities
        state.newestCelebrities.pop()

        state.isLoading = false
      })
      .addCase(getConfigs.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getConfigs.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default configsSlice.reducer
