import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getConfigs } from '../redux/configs/configs.actions'
import { selectIsLoading } from '../redux/configs/configs.selector'
import Footer from './footer'
import Header01 from './header/Header01'

export default function Layout({ children }) {
  const isLoading = useSelector(selectIsLoading)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getConfigs())
  }, [dispatch])

  if (isLoading) return null

  return (
    <>
      <Header01 />
      <main>{children}</main>
      <Footer />
    </>
  )
}
